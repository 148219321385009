import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  spotonEcomHero,
  spotonEcomWhiteGlove,
  spotonEcomLargeFeatures,
  spotonEcomSubverticals,
  spotonEcomSolutionsSection,
  websiteSolutionsSlides,
  spotonEcomTestimonials,
  spotonEcomCTA,
  spotonEcomFaqs,
} from "../../../data/product-pages/spoton-ecommerce-data";
import { autoBTSlides, heroSuccess } from "../../../data/success-stories-data";
import Hero from "../../../components/Hero/hero";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import Faqs from "../../../components/FAQs/Faqs";
// import Subverticals from "../../../components/SubVerticals/Subverticals";
import ogImage from "../../../images/global_assets/og-image.png";

const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/ECommerceLF")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const Faqs = loadable(() => import("../../../components/FAQs/Faqs"));
const Subverticals = loadable(() =>
  import("../../../components/SubVerticals/Subverticals")
);

const whiteGloveImport = "ecom-white-glove.png";

const SpotonEcommerce = () => {
  return (
    <Layout>
      <SEO
        title="POS System & Integration for ecommerce businesses | SpotOn"
        description="Integrating a pos system with your ecommerce store doesn't have to be complicated. Our all-in-one mobile & desktop system keeps everything streamlined. Demo now."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/e-commerce/"
      />
      <Hero sectionData={spotonEcomHero} circleBg={false} />
      {/* <TrustedNumbers numbersArray={spotonEcomTrusted} /> */}
      <WhiteGlove
        sectionData={spotonEcomWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={spotonEcomLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <Subverticals sectionData={spotonEcomSubverticals} />
      <SuccessStories sectionData={heroSuccess} slides={autoBTSlides} />
      <PartnerLogos />
      {/* <VideoSection sectionData={spotonEcomVideo} /> */}
      <ProductLines
        sectionData={spotonEcomSolutionsSection}
        productSlides={websiteSolutionsSlides}
      />
      <TestmonialReviews sectionData={spotonEcomTestimonials} />
      <PartnerLogos />
      {/* <IntegrationsSection
        sectionData={spotonEcomIntegrations}
        integrationList={restaurantData}
      /> */}
      <LargeCta sectionData={spotonEcomCTA} />
      <Faqs sectionData={spotonEcomFaqs} className="mb-120" />
      {/*      <Articles
        sectionData={restaurantArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default SpotonEcommerce;
