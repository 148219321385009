// eslint-disable-next-line import/prefer-default-export
export const spotonEcomHero = {
  title: "Sell online like a pro with our online retail & ecommerce solution",
  subtext:
    "Sell your products online without having to invest hundreds of hours and thousands of dollars building an online store yourself.",
  heroImg: "ecommerce-hero.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/e-commerce/demo",
  },
  fatTitle: "Ecommerce",
};

export const spotonEcomTrusted = [
  {
    title: "300m+",
    subtext: "Marketing campaigns",
  },
  {
    title: "5m+",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+",
    subtext: "Million consumers",
  },
];

export const spotonEcomWhiteGlove = {
  mainTitle: "The online store you deserve",
  title: "We build it for you",
  content:
    "Our team will work closely with you to make your online store with a product catalog that can also be published to your SpotOn point-of-sale device for in-store transactions, helping you save time and money while also reaching more customers. And we'll continue to be there when you need us with a dedicated local representative and 24/7 personalized support.",
  contentList: [
    "Lifetime support",
    "Self-service dashboard",
    "Optional custom website design with your branding and images",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/e-commerce/demo",
  },
};

export const spotonEcomLargeFeatures = {
  sectionTitle: "Everything you need, all in one place",
  featureBlocks: [
    {
      blockTitle: "The easiest and fastest way to sell online",
      blockSubTitle:
        "Accelerate your growth and reach more customers with an online store. Sell your products and services all in one place with powerful search, filtering, and sorting in every channel.",
      blockImg: "ecommerce-a.png",
      blockList: [
        "Ecommerce store—for both products and services",
        "Lightning fast—in 48 hrs or less",
        "Custom design with your branding and images",
        "Lifetime support",
        "Self-service dashboard ",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/e-commerce/demo",
      },
    },
    {
      blockTitle: "Easy for your customers. Easy for you",
      blockSubTitle:
        "Make it simple for your customers to add items to their cart and pay their way, with options for in-store pickup, shipping, and local delivery. Get alerted with every new order and use our built-in tools to save time and money while delivering a top notch customer experience.",
      blockImg: "ecommerce-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/e-commerce/demo",
      },
    },
    {
      blockTitle: "One catalog. All the channels",
      blockSubTitle:
        "Manage your catalog from one place, no matter which device you’re using. With SpotOn Retail's omnichannel catalog, you can easily create and update your catalogs for in-store and online sales. Any changes will show up on your SpotOn Retail POS and SpotOn ecommerce website ",
      blockList: [
        "Save time and avoid duplicate work",
        "Change descriptions, photos, prices, SKUs, and more",
        "Access it all from any POS device or computer",
        "List your services and rates in your catalog, then charge with just a few taps",
      ],
      blockImg: "ecommerce-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/e-commerce/demo",
      },
    },
    {
      blockTitle: "Let the professionals do the work for you",
      blockSubTitle:
        "Need a new website? We'll build a stunning site for you at a do-it-yourself price to help you get found more on Google and grow your business. No more struggling to get access from a freelancer or paying to make a simple update. Access your website dashboard any time to make quick changes to images, text, colors, and more. ",
      blockImg: "ecommerce-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/e-commerce/demo",
      },
    },
    {
      blockTitle: "Get all the features you need to sell more",
      blockSubTitle:
        "Create a better customer experience with all the features from our powerful SpotOn Dashboard to boost your store's earning potential.",
      blockList: [
        "Review management",
        "Reporting and invoicing",
        "Loyalty rewards program",
        "Email marketing and Facebook campaigns",
        "Online billing and payments",
      ],
      blockImg: "ecommerce-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/e-commerce/demo",
      },
    },
  ],
};

export const spotonEcomSubverticals = {
  title: "Just a few of our retail specialties",
  noMore: true,
  subverticalInfo: [
    {
      target: "/retail/clothing-and-apparel",
      iconImg: "clothing-icon.png",
      subverticalName: "Clothing & apparel",
      subverticalContent:
        "Sell in style with an integrated retail management system that makes it easy to stay connected with customers online and in-person.",
    },
    {
      target: "/retail/sporting-goods",
      iconImg: "sporting.png",
      subverticalName: "Sporting goods",
      subverticalContent:
        "Kick your game up a notch with high-performance tech to sell online and in-store, from one easily manageable catalog.",
    },
    {
      target: "/retail/home-decor",
      iconImg: "home-decor.png",
      subverticalName: "Home decor",
      subverticalContent:
        "Simplify how you sell with one system to sell both goods and services, so you can spend more time delighting your customers.",
    },
  ],
};

export const spotonEcomBusinessTypesCar = {
  title: "Not Tech-Savvy? No Problem",
  subtext:
    "Just explain your business to us and we'll take it from there. We promise to always speak to you in plain English, not techie-talk.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine dining. Casual dining.",
      cardIcon: "cart.svg",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent: "Repair. Parts.",
      cardIcon: "Wrench.svg",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent: "Hair salons. Nail salons.",
      cardIcon: "Vector.svg",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent: "Accountants. Plumbing.",
      cardIcon: "calendar.svg",
    },
  ],
};

export const spotonEcomVideo = {
  title: "See how it works",
  videoBtn: "Watch video",
  posterImg: "spotonEcom-poster.png",
  videoUrlId: "lIWWySVNAM0",
};

export const spotonEcomSolutionsSection = {
  title: "Bring it all together",
  subText: `Take your website productivity further by integrating other SpotOn services.`,
  // SecondaryCta: {
  //   ctaTitle: 'View all',
  //   ctaTarget: '/page-2',
  // },
};

export const spotonEcomTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const spotonEcomIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept spotonEcoms and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const spotonEcomCTA = {
  title: "Sign up for a free demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/e-commerce/demo",
  },
};

export const spotonEcomFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "https://help.spoton.com",
  },
  list: [
    {
      title:
        "I want to use a domain I bought previously from another company. Is this possible?",
      body: "Yes! We can connect your SpotOn website to your existing domain. Just let your specialist know and provide them with access to the registrar where you originally purchased your domain (GoDaddy, Name, Google Domains, etc.). They will assist you in making the connection.",
    },
    {
      title: "Does SpotOn Website provide email address services?",
      body: "We can set up email forwarding at no additional cost if you register a new domain through us.",
    },
    {
      title: "Can you insert software right into the website?",
      body: "You can add links to your website that redirect to third-party software. You can also add supported widgets using the HTML section on the website editor or by providing us an embed code.",
    },
    {
      title:
        "Can SpotOn take over my current website and just make changes to that?",
      body: "Our websites are platform-based. Therefore, while we can make a website that is similar in layout and design to your current site, we are unable to transfer it to our platform. We will build a new site within our guidelines to create a similar design that is professional and showcases your business.",
    },
  ],
};

export const spotonEcomSpecial = {
  title: "Websites for any type of business",
  subtext:
    "Our team of experts will create a custom website for you with the right solution for your business to create new sales opportunities.",
  img: "special-website.png",
  bgColor: "#F3F4F6",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/e-commerce/demo",
  },
  cardList: [
    "Restaurants, save $7,500/month on average compared to third-party delivery with our integrated online ordering.",
    "Salons, barbershops, and spas, boost sales by 40% or more with integrated appointment booking.",
    "Retailers, sell online with a custom built online storefront with a quick turnaround time-in as little as 48 hours!",
  ],
};

export const websiteSolutionsSlides = [
  {
    title: "Appointments",
    img: "Appointments1.png",
    link: "/products/appointments",
  },
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Mobile",
    link: "/products/mobile",
    img: "Mobile.png",
  },
  {
    title: "Virtual Terminal",
    link: "/products/virtual-terminal",
    img: "Virtual Terminal.png",
  },
  {
    title: "Online Ordering",
    link: "/products/online-order-and-delivery",
    img: "Online ordering.png",
  },
  {
    title: "Capital",
    link: "/products/capital",
    img: "Capital.png",
  },
  // {
  //   title: 'Payroll',
  //   link: '/products/payroll',
  //   img: 'Payroll.png',
  // },
  {
    title: "Delivery",
    link: "/products/delivery",
    img: "Delivery.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Review Management",
    link: "/products/review-management",
    img: "Review management.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Website & E-commerce",
    link: "/products/e-commerce",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
  {
    title: "Reporting",
    link: "/products/reporting",
    img: "Reporting.png",
  },
  {
    title: "Restaurant",
    link: "/restaurant-pos",
    img: "Restaurant.png",
  },
];
